import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./App.scss";
import App from "./App";
import LanguageInit from "./language";

const queryParams = new URLSearchParams(window.location.search);
const option = queryParams.get("opt");
const agency = queryParams.get("agency");
const order = queryParams.get("order");
const amount = queryParams.get("amount");
let lang = queryParams.get("lang");
const idSenderGlobal = queryParams.get("senderG");
const nameSender = queryParams.get("nameSender");
const iddoc = queryParams.get("iddoc");
const environment = queryParams.get("e");
const region = queryParams.get("r");
const iris = queryParams.get("i");
const selfie = queryParams.get("se");
const idShortener=queryParams.get("idS")
const isBackCapture=queryParams.get("bk")

if (lang) {
  if (lang !== "en" && lang !== "es") {
    lang = "en";
    localStorage.setItem("i18nextLng", lang);
  } else {
    localStorage.setItem("i18nextLng", lang);
  }
} else {
  const localStorageLanguage = localStorage.getItem("i18nextLng");

  if (localStorageLanguage !== "en" && localStorageLanguage !== "es") {
    localStorage.setItem("i18nextLng", "en");
    lang = "en";
  } else {
    lang = localStorageLanguage;
  }
}

ReactDOM.render(
  <Suspense fallback={<div>Loading...</div>}>
    <React.StrictMode>
      <LanguageInit />
      <App
        optionQS={option}
        agencyQS={agency}
        orderQS={order}
        amountQS={amount}
        language={lang}
        idSenderGlobal={idSenderGlobal}
        nameSender={nameSender}
        iddoc={iddoc}
        env={environment}
        region={region}
        iris={iris}
        selfie={selfie}
        idShortener={idShortener}
        isBackCapture={isBackCapture}
      />
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
